import React, {Component} from 'react'

import Header from "../partials/header"
import Footer from "../partials/footer"
import axios from "axios/index";
import {format} from 'date-fns';
import {isBrandLoggedin, brandloginPath, logout} from "../services/auth";
import {navigate} from "gatsby";
import {Link} from 'gatsby'

class scanqr extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            result: 'No result',
            totaldata: false,
            data: false,
        }
        this.handleScan = this.handleScan.bind(this);
    }

    componentDidMount = () => {
        if (!isBrandLoggedin()) {
            if (typeof window !== "undefined") {
                navigate('/brand-login')
            }
        } else {
            this.handleScan()
        }
    }

    /*Scan QR*/
    handleScan = (limit = 500, start = 0) => {
        let aruzyuser1 = {};
        let brandid = '';
        if (typeof window !== "undefined") {
            const isBrowser = typeof window !== "undefined";
            const aruzyuser = isBrowser ? localStorage.getItem('aruzyuser') : {};
           let aruzyuser1 = isBrowser && localStorage.getItem('aruzyuser') !== null ? JSON.parse(aruzyuser) : {};
               brandid=aruzyuser1.id
        }
        const data = axios.post(`${process.env.API_URL}/graphql`, {
            query: `query voucherscans{
                                    voucherscansConnection{
                                    aggregate {
                                          count
                                    } 
                                  }
                              voucherscans(where:{brand:${brandid}},limit:${limit},start:${start})  {   
                              id,
                              created_at,
                             voucher{ 
                               id,
                               location,
                               slug
                             offer,
                                logo{
                                  url 
                                    } 
                                  },
                                user{ 
                                  fname
                                }
                              ,voucherscan 
                              }   
                            }`,
        }).then((res) => {
            var totaldata = [];
            var datares = [];
            var ids = [];
            let temp = null;
            let totals;

            for (var v of res.data.data.voucherscans) {
                if (!ids.includes(v.voucher.id)) {
                    ids.push(v.voucher.id);
                    totaldata[v.voucher.id] = parseInt(v.voucherscan);
                    totals = res.data.data.voucherscans.reduce(function (r, o) {
                        if(v.voucher.id==o.voucher.id){
                            (r[v.voucher.id]) ? r[v.voucher.id] += o.voucherscan : r[v.voucher.id] = o.voucherscan;
                         }
                            return r;
                     }, {});
                     temp = Object.assign({}, JSON.parse(JSON.stringify(v)));

                    temp.voucherscan = totals[v.voucher.id];
                    temp.id = v.voucher.id + '-';
                    temp.type = 'parent';
                    temp.classname = 'parent';
                    datares.push(temp);
                    v.type = 'child' + temp.id;
                    v.classname = 'collapsed';
                    datares.push(v);
                } else {
                    totaldata[v.voucher.id] = parseInt(v.voucherscan) + parseInt(totaldata[v.voucher.id])
                    v.type = 'child' + v.voucher.id+ '-';
                    v.classname = 'collapsed';
                    datares.push(v);
                }
            }
            this.setState({data: datares})
            this.setState({totaldata: totaldata});
        })
    }

    changedateFormat = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy hh:mma');
    }

    showhide = event => {
        let el = event.target;
        var classname = "child" + event.target.parentNode.getAttribute('data-id')
        document.querySelectorAll('.parent' ).forEach(function (item) {
            item.classList.add('collapsed');
        });
        event.target.parentNode.classList.remove('collapsed');
        document.querySelectorAll('.' + classname).forEach(function (item) {
             if (item.classList.contains('collapsed')) {
                 item.classList.remove('collapsed');
            } else {
                 document.querySelectorAll('.parent' ).forEach(function (item) {
                     item.classList.remove('collapsed');
                 });
                item.classList.add('collapsed');
            }
        });
    }

    render() {
        return (
            <main>
                <Header/>
                <div className="container historytablecontainer">
                    <div className="col-md-6 text-left">
                      <span className="mb-2 cursor-pointer" onClick={event => {
                          event.preventDefault();
                          navigate(`/brand-home`)
                      }} style={{fontSize: '20px'}}>
                       <span className="fas fa-arrow-left" style={{fontSize: '18px', color: '#ff3c7c'}}/> Back
                      </span>
                    </div>
                    <h3>History Scan</h3>
                    <div className="offset-2 col-md-8">
                        <table className="historytable">
                            <thead>
                            <tr>
                                <th>Logo</th>
                                <th>Voucher</th>
                                <th>Scanned At</th>
                                <th>Total Scan</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.data !== false && this.state.data.map(tag =>
                                <tr key={tag.id} className={`  ${tag.type + ' ' + tag.classname}`}
                                    data-cat={tag.classname} onClick={this.showhide}
                                    data-id={tag.id}>
                                    <td>
                                        <img className="w-35" src={tag.voucher.offer.logo != null ? `${process.env.API_URL}` + tag.voucher.offer.logo.url : ''} alt={tag.voucher.offer}/>
                                    </td>
                                    <td>
                                        <Link to={'/vouchers/' + tag.voucher.slug}>{tag.voucher.location + ': ' + tag.voucher.offer}</Link>
                                    </td>
                                    <td> {tag.type != "parent" ? this.changedateFormat(tag.created_at) : ""}</td>
                                    <td> {tag.voucherscan}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Footer/>
            </main>
        )
    }
}

export default scanqr;